// Body
$body-bg: #f8fafc;
$body-color: #1D1D25;
$font-weight-bold: 600;
$font-weight-bold-700: 700;
$min-contrast-ratio: 2.5;

// Typography
$font-family-sans-serif: 'Century Gothic', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.72;

// Color
$gray-900: #797986;
$gray-600: #CED4DA;
$gray-300: #DEE2E6;
$gray-200: #E9ECEF;
$gray-100: #F1F1F4;
$red-600: #EA2340;
$black: #1D1D25;
$white: #FFFFFF;
$blue: #3467EB;
$bg-header: #F6F7F9;
$warning: #FFB44B;
$info: #AFE2F0;
$primary: #2D9BF0;
$green: #CEE741;

$pagination-active-bg: $red-600;
$pagination-color: $red-600;
$pagination-hover-color: $red-600;
$pagination-focus-color: $red-600;

$secondary:     $gray-900 !default;


$box-shadow: 0px 8px 16px rgba(29, 29, 37, 0.1);
$form-text-font-size: 14px;
$input-font-size: 14px;
$input-bg: $white;
$input-font-family: $font-family-sans-serif;
$input-font-weight: 400;
$input-placeholder-color: $gray-900;

$nav-ul-li-background-color: #F8F9FA;

$width-form: 400px;

$form-label-font-size: 13px;
$form-label-margin-bottom: 5px;
$form-label-font-weight: 700;
$form-select-border-radius: 0;
$form-select-border-radius: 0;
$input-border-radius: 0;
$input-border-color: $gray-600;
$input-padding-x: 10px;
$input-padding-y: 10px;
$input-font-size: 14px;
$input-line-height: 1.286;

$form-check-input-width: 1.1em;
$form-check-input-checked-bg-color: $red-600;
$form-check-input-checked-border-color: $red-600;

$form-file-button-color: $white;
$form-file-button-bg: $red-600;
$form-file-button-hover-bg: $red-600;

$btn-border-radius: 0px !important;

$table-border-width: 0;
$table-border-color: $gray-300;
$table-cell-padding-y: 7px;
$table-cell-padding-x: 8px;
$table-active-color: $gray-300 !default;
$table-active-bg-factor: .1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;
$table-cell-vertical-align: middle;
$table-striped-bg: $bg-header;


// modal
$modal-sm: 400px;
$modal-md: 480px;


//$form-select-padding-y: 10px 10px ;
//$form-select-padding-x: 5px ;
//

$s2bs5-padding-y: 10px 10px;
$s2bs5-padding-x: 5px;
$form-select-border-radius: 4px;
$s2bs5-clear-width:8px;
$s2bs5-clear-height:8px;
$s2bs5-height-inner: 0px;
$s2bs5-multi-item-padding-y: 0.15em;
$s2bs5-multi-item-padding-x: 0.65em;
$s2bs5-focus-box-shadow: none;
